.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}
.footer {
  background-color: #f5f5f5;
  margin: 0px auto;
  text-align: right;
  height: 60px;
  line-height: 60px;
}

footer p {
  padding: 20px 20px;
}

.navbar-dark {
  background-color: #00bcd4;
}

.btn-apply {
  background-color: #bfbfbf;
}
.section-search-filters {
  color: black;
  background-color: #c0c0c0;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  /* height: 2.375rem; */
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.table-thead {
  background-color: #00bcd4;
  color: white;
  text-align: center;
}
.span-date {
  width: 100%;
  background-color: #cfcfdb;
  font-size: 20px;
}
#root {
  background-color: #e4e4e4;
}

tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-curved {
  border-collapse: collapse;
  margin-left: 10px;
}

.table-curved td {
  position: relative;
  background-color: #ffffff;
  padding: 6px 10px;
  border-bottom: 2px solid rgb(165, 165, 165);
  border-top: 2px solid white;
}
.table-curved td:first-child:before {
  content: "";
  position: absolute;
  border-radius: 8px 0 0 8px;
  background-color: #00bcd4;
  width: 8px;
  height: 100%;
  left: -8px;
  top: 0px;
}
.table-curved td:last-child {
  border-radius: 0 8px 8px 0;
}
.table-curved tr.no td:last-child {
  border-radius: 0;
}
.table-curved tr:hover td {
  background-color: #c5c5c5;
}
.table-curved tr.blue td:first-child:before {
  background-color: #00bcd4;
}
.table-curved tr.gray td:first-child:before {
  background-color: #ced0ce;
}
.table-curved tr.green td:first-child:before {
  background-color: forestgreen;
}
.table-curved tr.no td:first-child:before {
  display: none;
}
.table-curved tr.no:hover td {
  background-color: #e4e4e4;
}
.table-curved tr.no td {
  background-color: #e4e4e4;
  font-size: 20px;
}

.noti-container {
  position: relative;
  z-index: 0;
}

.noti-counter {
  display: block;
  position: absolute;
  background: #e1141e;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
  padding: 1px 5px;
  margin: -8px 0 0 25px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  z-index: 1;
}

.detail-label {
  color: gray;
  font-size: 12px;
  font-weight: 400;
}
.detail-label-info {
  /* color: white; */
  font-size: 12px;
  font-weight: 400;
}
.detailblock.gray {
  border-left: 9px solid gray;
  border-radius: 10px;
}
.detailblock.blue {
  border-left: 9px solid #00bcd4;
  border-radius: 10px;
}

.detailblock.green {
  border-left: 9px solid forestgreen;
  border-radius: 10px;
}

.dropdown-block {
  background-color: #cfcfdb;
  font-weight: 400;
  border-radius: 10px;
}

.nodiv {
  background-color: #cfcfdb;
}
.react-datepicker-popper {
  z-index: 3;
}
.datepicker {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 35%;
}
.dropdown-menu {
  overflow-y: scroll;
  max-height: 200px;
}

#seconddiv.coolclass {
  max-height: 100%;
  /* background: purple; */
  opacity: 1;
}

#seconddiv {
  max-height: 0;
  opacity: 0;
  transition: 0.5s;
  overflow: hidden;
}

.comment-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: 80vh;
  overflow-y: auto;
  display: block !important;
}

.comment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 10ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
} */

.paginationText {
  font-size: 13px;
  margin-top: 11px;
}

.app {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.bg-custom {
  background-color: #00bcd4;
}
nav a {
  color: white;
}

.custom-nav {
  max-height: 77px;
}

.color-red {
  background-color: red;
  color: red;
}

.custom-main {
  margin-top: 72px; /*temp fix for body to fit between nav and footer*/
  background-color: white;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  /* For general mobile layouts */
  .custom-nav {
    max-height: 100px;
  }
  .custom-main {
    margin-top: 100px;
  }
}

.search-grid {
  background-color: white;
}

.nav-link {
  color: white !important;
}

.nav-link:active,
.nav-link:hover {
  color: #747578 !important;
}

.padding-top-25px {
  padding-top: 25px;
}

.height-100vh {
  height: 100vh;
}

.padding-10-all {
  padding: 10;
}

.table-header-font {
  font-size: 14px;
  font-weight: 500px;
}

.detail-rounded {
  border-radius: 10px !important;
}
.step-checkbox {
  width: 20px;
  height: 20px;
}
.dash-container {
  max-width: 80%;
  margin: 72px auto;
  padding-top: 20px;
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas:
    "infocards infocards infocards"
    "sidebar chart charts"
    "footer footer footer";

  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.dash-infocards {
  grid-area: infocards;
  margin: auto auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.dash-card {
  border-radius: 10px;
  background-color: #f9f9f9;
  margin: 10px;
  padding: 5px;
  position: relative;
  box-shadow: 3px 3px 3px gray;
  overflow: auto;
  text-align: center;
}

.dash-card h2 {
  color: black;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 5px;
}

.dash-card h3 {
  color: #00bcd4;
  margin-bottom: 2rem;
}

.dash-sidebar {
  border-radius: 10px;
  background-color: #f9f9f9;
  margin: 10px auto;
  padding: 5px;
  position: relative;
  box-shadow: 3px 3px 3px gray;
}

.dash-sidebar input {
  /* width: 100%; */
  justify-self: start;
}

.dash-chart {
  grid-area: chart;

  grid-column: 2 / 4;

  border-radius: 10px;
  background-color: #f9f9f9;
  margin: 10px auto;
  padding: 5px;
  position: relative;
  box-shadow: 3px 3px 3px gray;
  overflow: auto;
}
.dash-padd {
  margin-bottom: 60px;
}
.dash-dateRangePicker {
  width: 500px;
}
